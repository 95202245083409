<template>
  <v-form>
    <v-row>
      <v-col
        cols="5"
      >
        <v-text-field
          v-model="form.name"
          :label="$vuetify.lang.t('$vuetify.equipmentTemplate.name')"
          clearable
        />
      </v-col>
      <v-col
        cols="5"
      >
        <v-select
          v-model="form.type"
          :label="$vuetify.lang.t('$vuetify.equipmentTemplate.type')"
          :items="typeItems"
          item-text="paramName"
          item-value="id"
          clearable
        ></v-select>
      </v-col>
      <v-col
        cols="2"
      >
        <v-btn
          class="mx-2"
          color="primary"
          fab
          small
          @click="handleSearch"
        >
          <v-icon dark>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        form: {
          name: null,
          type: null
        },
        depItems: [],
        provinceItems: [],
        cityItems: [],
        countyItems: [],
        typeItems: []
      }
    },
    computed: {
      ...mapGetters({
        paramInfos: 'paramInfo/paramInfos'
      }),
    },
    created () {
      this.initialize()
    },
    methods: {

      ...mapActions({
        queryAllByTypeCode: 'paramInfo/queryAllByTypeCode'
      }),

      initialize () {
        this.queryAllByTypeCode('EquipmentType').then( () => {
          this.typeItems = JSON.parse(JSON.stringify(this.paramInfos))
        })
      },
      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
